div.MainBlock {
    background-color: #F3F3F3;
    border-radius: 28px;
    width: 85%;
}

div.InfoHeader {
    border-bottom: 2px solid #7CA5E3;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

div.PictureName {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

div.PictureName > p {
    font-weight: bold;
    font-size: 20px;
}

div.PictureName > div.Clickable {
    cursor: pointer;
}

div.PictureName > div.ImageContainer {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
}

.ImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: flex-start;
}

ul.Counters {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 64px;
    padding-left: 0;
    margin: 0;
}

li.Counter {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

li.Counter > :first-child {
    font-weight: 600;
}

li.Counter > :last-child {
    color: #406DC5;
    font-weight: 600;
}

div.ProfileInfo {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    gap: 32px;
}

ul.Fields {
    padding-left: 0;
    list-style-type: none;
    width: 50%;
    margin: 0;
}

ul.Fields > li > :first-child {
    font-weight: 600;
}

ul.Fields > li > :last-child {
    background-color: #D9D9D9;
    border-radius: 11px;
    padding: 12px;
    margin-left: 16px;
    white-space: pre-wrap;
}

div.ContactsBlock {
    width: 50%;
}

div.ContactsBlock > p {
    font-weight: 600;
    margin-bottom: 0;
}

ul.Contacts {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
}

ul.Contacts > li {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

ul.Contacts > li > :last-child {
    background-color: #D9D9D9;
    border-radius: 11px;
    padding: 12px;
    width: 100%;
}

div.ButtonBottom {
    padding: 16px;
}

button.Change {
    background: linear-gradient(103.69deg, #E2A4FF 16.17%, #9450B4 89.17%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 16px;
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

button.Change:active {
    transform: translateY(1px);
}

button.SubscribeButton {
    margin-left: auto;
    background: linear-gradient(103.69deg, #E2A4FF 16.17%, #9450B4 89.17%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 16px;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
}

button.UnsubscribeButton {
    margin-left: auto;
    background: linear-gradient(103.69deg, #e2d3e5 16.17%, #d082ed 89.17%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 16px;
    border: none;
    color: #8F4EE3;
    font-size: 16px;
    cursor: pointer;
}

button.active {
    transform: translateY(1px);
}