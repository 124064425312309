.FormPage {
    display: flex;
    width: 99%;
    height: 99vh;
    align-items: center;
    justify-content: center;
}

.FormContainer {
    border-radius: 32px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: 40px;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.FormTitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: #4176D2;
}

.FormField {
    background-color: #D3E0F5;
    border-radius: 13px;
    border: none;
    color: #3962A5;
    padding: 12px;
    width: 80%;
    align-self: center;
    font-size: 16px;
    resize: none;
}

.BigFormField {
    min-height: 200px;
}

.FormButton {
    background: linear-gradient(103.69deg, #E2A4FF 16.17%, #9450B4 89.17%);
    box-shadow: 0 4px 8px #E2A4FF;
    border-radius: 225px;
    border: none;
    color: #FFFFFF;
    padding: 16px;
    font-size: 18px;
    font-weight: 400;
    width: 20%;
    align-self: center;
    cursor: pointer;
}

.FormButton:active {
    transform: translateY(1px);
}

.DownText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}