.projectCard {
    overflow: hidden;
    background-color: #FFFFFF;
    width: 80%;
    min-width: 500px;
    max-width: 80vh;
    margin: 0 auto;
    height: 100%;
}

.projectCard{
    border-radius: 21px;
}

.userInfo {
    padding: 10px 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: #8AB1EB solid 1px;
    font-weight: 400;
    font-size: 16px;
    gap: 16px;
}

.userInfo > .ImageContainer {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
}

.ImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: flex-start;
}

.projectInfo {
    padding: 16px 36px;
}

.projectInfo > .ImageContainer {
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    cursor: pointer;
}

.projectTitle {
    font-weight: 500;
    font-size: 17px;
    margin: 0 0 16px 0;
    cursor: pointer;
}

.projectDescription {
    font-weight: 300;
    font-size: 16px;
    color: #262626;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
}

.projectMeta {
    display: flex;
    align-items: center;
}

.projectCreatedAt {
    margin-right: auto;
}

.iconWithText {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}

.Icon {
    cursor: pointer;
}