.ProjectPicture {

}

.ProjectPicture .ImageContainer {
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.ImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProjectPicture .PictureDescription {
    color: #4F4F4F;
    font-size: 18px;
    text-align: center;
    overflow: hidden;
}