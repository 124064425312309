.navigation {
    padding: 16px 64px 16px 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navigationItems .navItem {
    display: inline-block;
    padding: 0 16px 0 16px;
}

.mainLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    text-decoration: none;
}

.buttonWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    border-radius: 64px;
    font-weight: 600;
    gap: 16px;
}

.profileButton {
    background: linear-gradient(#B275FF 1%, #7B37D2 100%);
    box-shadow: 0 8px 32px #DE7EF6;
    color: #FFFFFF;
}

.searchWithIcon.loupe .input {
    background-image: url('../../img/Loupe.svg');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    background-color: #8AB1EB;
    box-sizing: border-box;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ccc;
    border-radius: 16px;
    width: 100%;
    font-size: 16px;
    color: #FFFFFF;
}

.link.navItem p{
    font-weight: 400;
    color: #FFFFFF;
}

.link.navItem a{
    text-decoration-color: #FFFFFF;
    text-underline-offset: 4px;
}

.dropdown {
    position: relative;
}

.dropdownButton {
    margin: 8px 0;
}

.dropdownContent {
    display: none;
    position: absolute;
    width: 90%;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}

.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

ul.dropdownContent {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: linear-gradient(99.87deg, #B376FF 6.82%, #7C37D3 97%);
    border-radius: 10px;
}

.dropdownContent li {
    background: none;
    color: white;
    padding: 16px;
}

.dropdownContent button {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    cursor: pointer;
}

.dropdownContent a:hover {background-color: #ddd;}

.dropdown:hover .dropdownContent {display: block;}
