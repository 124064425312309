@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background: linear-gradient(136.02deg, #7CA5E3 9.86%, #195DC3 87.57%),
    radial-gradient(54.92% 54.92% at 38.54% 80.1%, rgba(178, 118, 255, 0.79) 0%, rgba(138, 177, 235, 0) 100%) no-repeat;
    min-height: 100%;
    background-size: cover;
}





