.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    z-index: 999;
}

.ModalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

.ModalContent {
    width: 80%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding: 40px;
    transform: translateY(-50px);
    transition: opacity 0.3s, transform 0.3s;
    display: flex;
    flex-direction: column;
}

.CloseButton {
    margin-left: auto;
    cursor: pointer;
}