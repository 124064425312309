input[type=file].HiddenInput  {
    display: none;
}

label {
    display: flex;
    flex-direction: column;
}

.DownText {
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    border-radius: 20px;
    background-color: rgba(87, 111, 179, 0.78);
    padding: 4px;
    width: 30%;
    align-self: center;
}

.ImageContainer {
    aspect-ratio: 16 / 9;
    display: flex;
    border-radius: 5px;
    background-color: #E0EBFE;
    justify-content: center;
    align-items: center;
    font-size: 64px;
    color: #708ACE;
    cursor: pointer;
    overflow: hidden;
}

.ImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: flex-start;
}